import React, { useEffect, useState } from 'react'
import { faMountain } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faTwitter, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons'

// import { faTwitter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import Contact from './Contact'



function Navbar() {
    const [navState, setNavState] = useState()
    const [contact, setContact] = useState(false)
    const [imgState, setImgState] = useState('./will/mouth-close.png')


    const handleNavToggle = () => {
        const nav = document.querySelector("nav");

        nav.dataset.transitionable = "true";
        nav.dataset.toggled = nav.dataset.toggled === "true" ? "false" : "true";
    }

    window.matchMedia("(max-width: 800px)").onchange = e => {
        const nav = document.querySelector("nav");

        nav.dataset.transitionable = "false";
        nav.dataset.toggled = "false";
    };

    const handleClick = () => {
        setContact(!contact)
    }

    return (
        <>
            <nav data-toggled="false" data-transitionable="false">
                <div id='nav-logo-section' className='nav-section'>
                    {/* <FontAwesomeIcon style={{ color: 'white' }} size='2x' icon={faMountain} /> */}
                    <img onMouseLeave={() => setImgState('./will/mouth-close.png')} onMouseEnter={() => setImgState('./will/mouth-open.png')} src={require(`${imgState}`)} height='80px' />
                </div>

                <div id="nav-mobile-section">
                    {/* <div id='nav-link-section' className='nav-section'>
                        <p id='nav-link-section-p'><Link onClick={handleNavToggle} to='/about'>ABOUT</Link></p>
                        <p id='nav-link-section-p'><Link onClick={handleNavToggle} to='/'>WORK</Link></p>
                        <Link onClick={handleNavToggle} to='/test-kitchen'>TEST KITCHEN</Link>
                    </div> */}
                    <div id='nav-social-section' className='nav-section'>

                        <a onClick={handleNavToggle} href='https://github.com/wsiddons' target='_blank'>
                            <FontAwesomeIcon style={{ color: 'white' }} size='2x' icon={faGithub} />
                        </a>
                        <a onClick={handleNavToggle} href='https://www.linkedin.com/in/willsiddons/' target='_blank'>
                            <FontAwesomeIcon style={{ color: 'white' }} size='2x' icon={faLinkedin} />
                        </a>
                    </div>
                    <div id='nav-contact-section' className='nav-section'>
                        <p id='nav-link-section-p'><Link onClick={handleNavToggle} to='/'>WORK</Link></p>

                        <p id='nav-link-section-p'>
                            <Link onClick={handleNavToggle} to='contact'>GET IN TOUCH</Link>
                        </p>
                    </div>
                </div>
                <button id="nav-toggle-button" type="button" onClick={handleNavToggle}>
                    <span>Menu</span>
                    <i className="fa-solid fa-bars"></i>
                </button>
            </nav>

        </>
    )
}

export default Navbar