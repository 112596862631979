import React from 'react'

function Contact() {
    return (
        <>
            <div className='contact-container'>
                <p>wsiddons11@gmail.com</p>
                <p>310.767.6752</p>
                <a href={require('../../assets/resume.pdf')} download>Resume</a>
            </div>
        </>
    )
}

export default Contact