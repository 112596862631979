import React, { useEffect, useState, useRef } from 'react'


function Anim() {
    const [configState, setConfigState] = useState(1)
    const [roundnessState, setRoundnessState] = useState(1)
    const [indexState, setIndexState] = useState({ configuration: 1, roundness: 1 })


    const rand = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)

    const combinations = [
        { configuration: 1, roundness: 1 },
        { configuration: 1, roundness: 2 },
        { configuration: 1, roundness: 4 },
        { configuration: 2, roundness: 2 },
        { configuration: 2, roundness: 3 },
        { configuration: 3, roundness: 3 },
    ]
    // console.log(wrapperEle)

    // setInterval(() => {
    //     const index = rand(0, combinations.length - 1),
    //         combination = combinations[index]
    //     setIndexState(combinations[index])
    //     console.log(indexState, combinations[index])

    // }, 5000)

    const random3 = () => (Math.floor(Math.random() * 3) + 1)
    const random4 = () => (Math.floor(Math.random() * 4) + 1)
    // useEffect(() => {
    //     setInterval(() => {
    //         console.log(random3(), random4())
    //         setRoundnessState(random4())
    //         setConfigState(random3())
    //     }, 3000)
    // })

    useEffect(() => {
        const interval = setInterval(() => {
            console.log('This will be called every 2 seconds');
            console.log(random3(), random4())
            setRoundnessState(random4())
            setConfigState(random3())
        }, 3000);

        return () => clearInterval(interval);
    }, []);


    return (
        <>
            <div id='wrapper' data-configuration={configState} data-roundness={roundnessState}>
                <div className='shape'></div>
                <div className='shape'></div>
                <div className='shape'></div>
                <div className='shape'></div>
                <div className='shape'></div>
                <div className='shape'></div>
                <div className='shape'></div>
            </div >
        </>
    )
}

export default Anim