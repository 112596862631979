import React from 'react'

function Lookbook() {
    return (
        <div className='linkuistix-project-container'>
            <h1>MARVEL x BO BRIDGES LOOK BOOK</h1>

            <img id='lookbook-img' src={require('../../../assets/boLookbook/marvel-lookbook.png')} alt='bos lookbook' />
            <div className='lookbook-img'>
                <img src={require('../../../assets/boLookbook/bobooklet1.png')} alt='bos lookbook' />
                <img src={require('../../../assets/boLookbook/bobooklet2.png')} alt='bos lookbook' />
            </div>
            <div className='lookbook-img'>
                <img src={require('../../../assets/boLookbook/bobooklet3.png')} alt='bos lookbook' />
                <img src={require('../../../assets/boLookbook/bobooklet4.png')} alt='bos lookbook' />
            </div>


        </div>
    )
}

export default Lookbook