import React from 'react'

function CommunityCanvas() {
    return (
        <>
            <div className='linkuistix-project-container'>
                <h1>COMMUNITY CANVAS</h1>
                <p>The Community Canvas project is my attempt at making a online whiteboard editable and viewable by anyone who signs up and makes an account. When a user holds their left mouse button down a stroke is started with a pixel width and color determined by the yellow controls to the right of the canvas. I'll also note I added an option to the controls that allows the user to download the canvas as a png in case they want to locally save their work on the canvas.</p>


                <img src={require('../../../assets/whiteboard/colorpicker.gif')} />
                <h1>Firebase Firestore</h1>
                <p>Every time the user finishes a stroke, a new canvas HTML element is turned into a new blob and sent to the database so users across the world can see the updates made to the canvas.</p>
                <img src={require('../../../assets/whiteboard/ccfirestore.png')} />
                <h1>User Authentication</h1>
                <p>User authentication and sign up is handled through Google's Firebase Authentication. This was my first time using Firebase and I have to say, it was very straightforward to integrate with my React app.</p>
                <img src={require('../../../assets/whiteboard/cclogin.png')} />
                <img src={require('../../../assets/whiteboard/ccsignup.png')} />
                <img src={require('../../../assets/whiteboard/ccfirebaseauth.png')} />
            </div>
        </>
    )
}

export default CommunityCanvas