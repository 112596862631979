import React from 'react'

function Frama() {

    return (
        <>
            <div className='linkuistix-project-container'>
                <h1>FRAMA BRAND REDESIGN</h1>
                <p>Frama stands apart for its commitment to simplicity and use of natural, sustainable materials. They stand out as a Danish design brand by expanding past classic Danish design paradigms, such as furniture and architectural designs.</p>

                <img src={require('../../../assets/frama/frama-finals.png')} />
                <img src={require('../../../assets/frama/frama-biz-card.png')} />
                <img src={require('../../../assets/frama/frama-posters.png')} />
                <img src={require('../../../assets/frama/frama-stamp.png')} />
                <img src={require('../../../assets/frama/frama-labels.png')} />
                <img src={require('../../../assets/frama/frama-tote.png')} />
            </div>
        </>
    )
}

export default Frama


    // < div className = 'single-project-container' >
    //             <div className='image'>
    //                 <img src={require('../../../assets/frama/frama-splash.png')} />
    //             </div>
    //             <div className='text'>
    //                 <h2>About</h2>
    //                 <p>Frama stands apart for its commitment to simplicity and use of natural, sustainable materials. They stand out as a Danish design brand by expanding past classic Danish design paradigms such as furniture and architectural designs. Visually, Frama stands out for its simple/minimalistic design notes and use of natural, nuetral colors. </p>
    //             </div>
    //             <div className='text'>
    //                 <h2>Business Card Redesign</h2>
    //             </div>
    //             <div className='image'>
    //                 <img src={require('../../../assets/frama/frama-biz-card.png')} />
    //             </div>
    //             <div className='image'>
    //                 <img src={require('../../../assets/frama/frama-posters.png')} />
    //             </div>
    //             <div className='text'>
    //                 <h2>Poster Design</h2>
    //                 <p>I started the design process thinking about where the text would be placed. I then chose an image that would frame the text is a pleasing way. At first I opted for lots of whitespace but chose to go with a full image background as I though it added depth and complexity to the piece.</p>
    //             </div>
    //             <div className='text'>
    //                 <h2>Stamp Design</h2>
    //                 <p> Throughout this project I knew repeaded lines would be
    //                     incorporated in some way. I experiemented heavily with zooming in on imporant aspects of the FRAMA logo and icon I had designed. All said and done I opted for a bold, stand alone versoin of the icon.</p>
    //             </div>
    //             <div className='image'>
    //                 <img src={require('../../../assets/frama/frama-stamp.png')} />
    //             </div>
    //             <div className='image'>
    //                 <img src={require('../../../assets/frama/frama-labels.png')} />
    //             </div>
    //             <div className='text'>
    //                 <h2>Apothecary Labels Redesign</h2>
    //                 <p> The apothecary label came to fruition towards the end of the project. It had many different variations because I had the
    //                     elements finalized, I just had to figure out how best to a
    //                     ssemble them onto a label.</p>
    //             </div>
    //         </div >