import React from 'react'

function Joel() {
    return (
        <div className='linkuistix-project-container'>
            <h1>SMALL BUSINESS CLOSURES MINI DOCUMENTARY</h1>
            <iframe id='joel-embed' height='100%' src="https://www.youtube.com/embed/8oJDiW1Zw20" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <img src={require('../../../assets/Joel/joel1.jpg')} alt='hair stylist imagery' />
            <img src={require('../../../assets/Joel/sam1.jpg')} alt='hair stylist imagery' />
            <img src={require('../../../assets/Joel/joel2.jpg')} alt='hair stylist imagery' />
        </div>
    )
}

export default Joel