import Landing from "./components/Landing/Landing";
import Navbar from "./components/Navbar/Navbar";
import { Routes, Route } from 'react-router-dom';
import Linkuistix from "./components/Projects/Linkuisitix/Linkuistix";
import Frama from "./components/Projects/Frama/Frama";
import LeagueTracker from "./components/Projects/LeagueTracker/LeagueTracker";
import CommunityCanvas from "./components/Projects/CommunityCanvas/CommunityCanvas";
import About from "./components/Navbar/About/About";
import Contact from "./components/Navbar/Contact";
import TestKitchen from "./components/Navbar/TestKitchen";
import Joel from "./components/Projects/Joel/Joel";
import Lookbook from "./components/Projects/Lookbook/Lookbook";


function App() {

  return (
    <>
      <Navbar />
      {/* <Anim /> */}

      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='/linkuistix' element={<Linkuistix />} />
        <Route path='/frama-redesign' element={<Frama />} />
        <Route path='/lol-stat-tracker' element={<LeagueTracker />} />
        <Route path='/community-whiteboard' element={<CommunityCanvas />} />
        <Route path='/covid-mini-doc' element={<Joel />} />
        <Route path='/marvel-lookbook' element={<Lookbook />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/test-kitchen' element={<TestKitchen />} />
      </Routes>
    </>
  );
}

export default App;
