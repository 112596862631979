import { Canvas } from '@react-three/fiber'
import React from 'react'
import Anim from './Anim'
import Box from './Box'
// import CameraController from './OrbitalControls'

function About() {
    //postion canvas

    return (
        // <Canvas>
        //     {/* < /> */}
        //     <ambientLight />
        //     <pointLight position={[10, 10, 10]} />

        //     <Box position={[0, 0, 0]} />
        // </Canvas>
        <>
            <Anim />
            <h1 style={{ textAlign: 'center', marginTop: '30vh' }}>Under Construction</h1>
        </>
    )
}

export default About