import React from 'react'

function Linkuistix() {
    return (
        <div className='linkuistix-project-container'>
            <h1>Linkuisitx Capstone Project</h1>
            <iframe height='100%' src="https://www.youtube.com/embed/GvZ8XhF3Jz4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <p>Linkuistix is a word association game that is built with the <a target="_blank" href='https://tfhub.dev/google/universal-sentence-encoder/4'>Universal Sentence Encoder</a> (USE) model, available from the <a target='_blank' href='https://www.tensorflow.org/hub'>TensorFlow Hub</a>. The USE encodes text into 512-dimensional embeddings, which can be used to determine <a target='_blank' href='https://en.wikipedia.org/wiki/Semantic_similarity'>semantic similarity</a>.</p>
            <img src={require('../../../assets/linkuistix/lkxGameplay1.gif')} />

            <p>The USE was trained on different textual inputs from the internet and, beyond comparing individual strings, can also predict words or phrases that might come next in a conversation. Further technical information on this model can be found in the original Google Research paper by <a target='_blank' href='https://arxiv.org/abs/1803.11175'>Cer et al. (2018)</a>.</p>
            <img src={require('../../../assets/linkuistix/semantic-textual-similarity.png')} />

            <p>In this game, players are presented a target word and are required to submit an associated word to score points. For example, if the target word is cat, the player may enter kitten.</p>
            <h2>Technology Stack</h2>
            <div className='tech-img-container'>
                <img className='tech-img' src={require('../../../assets/linkuistix/js.png')} />
                <img className='tech-img' src={require('../../../assets/linkuistix/tf.png')} />
                <img className='tech-img' src={require('../../../assets/linkuistix/px.png')} />
                <img className='tech-img' src={require('../../../assets/linkuistix/react.png')} />
                <img className='tech-img' src={require('../../../assets/linkuistix/njs.png')} />
                <img className='tech-img' src={require('../../../assets/linkuistix/fb.png')} />
            </div>
            <h2><a href='https://linkuistix.com/'>Play The Game!</a></h2>
        </div>
    )
}

export default Linkuistix