import React from 'react'

function LeagueTracker() {
    return (
        <div className='linkuistix-project-container'>
            <h1>LEAGUE OF LEGENDS STAT TRACKER</h1>
            <p>This web app was inspired by other production level League of Legends stat trackers such as u.gg or op.gg. My app allows the user to search their League of Legends username and get pertinent information associated with the past 5 games played on their account. The purpose of this project was to hone my web development skills. It utilizes Express to handle API calls made to a proxy server that then get sent out to the League of Legends API to recieve live data about the player. Client side data is managed through a Redux store and the app was build using React.</p>
            <img src={require('../../../assets/lol/league.gif')} />
            <h2>Redux Store</h2>
            <img src={require('../../../assets/lol/lolredux.png')} />
            <p>There is so much data associated with each game played. I was able to manage and map through this data easily on the client side using a Redux store.</p>
            <h2>API Routes</h2>
            <img src={require('../../../assets/lol/lolexpress.png')} />
        </div>
    )
}

export default LeagueTracker