import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'



function Landing() {
    let activeIndex = 0
    const slides = document.getElementsByTagName('article')
    const handleLeftClick = () => {
        const nextIndex = activeIndex - 1 >= 0 ? activeIndex - 1 : slides.length - 1

        const currentSlide = document.querySelector(`[data-index="${activeIndex}"]`),
            nextSlide = document.querySelector(`[data-index="${nextIndex}"]`);

        currentSlide.dataset.status = 'after'

        nextSlide.dataset.status = "becoming-active-from-before";

        setTimeout(() => {
            nextSlide.dataset.status = "active";
            activeIndex = nextIndex;
        });
    }
    const handleRightClick = () => {
        const nextIndex = activeIndex + 1 <= slides.length - 1 ? activeIndex + 1 : 0;

        const currentSlide = document.querySelector(`[data-index="${activeIndex}"]`),
            nextSlide = document.querySelector(`[data-index="${nextIndex}"]`);

        currentSlide.dataset.status = "before";

        nextSlide.dataset.status = "becoming-active-from-after";

        setTimeout(() => {
            nextSlide.dataset.status = "active";
            activeIndex = nextIndex;
        });
    }

    return (
        <>
            <main>
                <article data-index='0' data-status='active'>
                    <div className='article-image-section article-section'></div>
                    <div className='article-description-section article-section'>
                        <p>This was my capstone project for the Fullstack Academy bootcamp in web development. Linkuistix is a word association game that uses an artificial intelligence trained by machine learning.</p>
                    </div>
                    <div className='article-title-section article-section'>
                        <h2>LINKUISTIX CAPSTONE PROJECT</h2>
                        <Link to='/linkuistix'>
                            <FontAwesomeIcon className='plus' icon={faPlus} style={{ color: 'rgb(255, 255, 255, 0.1)' }} size='4x' />
                        </Link>
                    </div>
                    <div className='article-nav-section article-section'>
                        <button className='article-nav-button' onClick={handleLeftClick}>
                            <FontAwesomeIcon icon={faArrowLeft} style={{ color: 'rgb(255, 255, 255, 1)' }} size='2x' />
                        </button>
                        <button className='article-nav-button' onClick={handleRightClick}>
                            <FontAwesomeIcon icon={faArrowRight} style={{ color: 'rgb(255, 255, 255, 1)' }} size='2x' />
                        </button>
                    </div>
                </article>
                <article data-index='1' data-status='inactive'>
                    <div className='article-image-section article-section'></div>
                    <div className='article-description-section article-section'>
                        <p>In the Spring of 2020, I studied Graphic Design while I was abroad in Copenhagen. The final project was to redesign the famous Copenhagen based design brand, Frama.</p>
                    </div>
                    <div className='article-title-section article-section'>
                        <h2>FRAMA BRAND REDESIGN</h2>
                        <Link to='/frama-redesign'>
                            <FontAwesomeIcon className='plus' icon={faPlus} style={{ color: 'rgb(255, 255, 255, 0.1)' }} size='4x' />
                        </Link>
                    </div>
                    <div className='article-nav-section article-section'>
                        <button className='article-nav-button' onClick={handleLeftClick}>
                            <FontAwesomeIcon icon={faArrowLeft} style={{ color: 'rgb(255, 255, 255, 1)' }} size='2x' />
                        </button>
                        <button className='article-nav-button' onClick={handleRightClick}>
                            <FontAwesomeIcon icon={faArrowRight} style={{ color: 'rgb(255, 255, 255, 1)' }} size='2x' />
                        </button>
                    </div>
                </article>
                <article data-index='2' data-status='inactive'>
                    <div className='article-image-section article-section'></div>
                    <div className='article-description-section article-section'>
                        <p>The League of Legends Stat Tracker was another project completed during my time at Fullstack Academy. This was the first solo fullstack web application I ever made. I made use of the League of Legends API to get pertinent player information to be displayed in a clean, readable user interface.</p>
                    </div>
                    <div className='article-title-section article-section'>
                        <h2>LEAGUE STAT TRACKER</h2>
                        <Link to='/lol-stat-tracker'>
                            <FontAwesomeIcon className='plus' icon={faPlus} style={{ color: 'rgb(255, 255, 255, 0.1)' }} size='4x' />
                        </Link>
                    </div>
                    <div className='article-nav-section article-section'>
                        <button className='article-nav-button' onClick={handleLeftClick}>
                            <FontAwesomeIcon icon={faArrowLeft} style={{ color: 'rgb(255, 255, 255, 1)' }} size='2x' />
                        </button>
                        <button className='article-nav-button' onClick={handleRightClick}>
                            <FontAwesomeIcon icon={faArrowRight} style={{ color: 'rgb(255, 255, 255, 1)' }} size='2x' />
                        </button>
                    </div>
                </article>
                <article data-index='3' data-status='inactive'>
                    <div className='article-image-section article-section'></div>
                    <div className='article-description-section article-section'>
                        <p>The Community Canvas Project was completed during my time at Fullstack Academy web development bootcamp. It was intended to utilize new technologies I was unfamiliar with. I worked with Firebase to handle user login as well as sending and receiving data to and from Firestore.</p>
                    </div>
                    <div className='article-title-section article-section'>
                        <h2>COMMUNITY CANVAS WEB APP</h2>
                        <Link to='/community-whiteboard'>
                            <FontAwesomeIcon className='plus' icon={faPlus} style={{ color: 'rgb(255, 255, 255, 0.1)' }} size='4x' />
                        </Link>
                    </div>
                    <div className='article-nav-section article-section'>
                        <button className='article-nav-button' onClick={handleLeftClick}>
                            <FontAwesomeIcon icon={faArrowLeft} style={{ color: 'rgb(255, 255, 255, 1)' }} size='2x' />
                        </button>
                        <button className='article-nav-button' onClick={handleRightClick}>
                            <FontAwesomeIcon icon={faArrowRight} style={{ color: 'rgb(255, 255, 255, 1)' }} size='2x' />
                        </button>
                    </div>
                </article>
                <article data-index='4' data-status='inactive'>
                    <div className='article-image-section article-section'></div>
                    <div className='article-description-section article-section'>
                        <p>This short documentary aims to not only give insight into the operation of a small business during the covid-19 pandemic but also goes into the man behind the business. </p>
                    </div>
                    <div className='article-title-section article-section'>
                        <h2>COVID SMALL BUSINESS CLOSURES MINI-DOC</h2>
                        <Link to='/covid-mini-doc'>
                            <FontAwesomeIcon className='plus' icon={faPlus} style={{ color: 'rgb(255, 255, 255, 0.1)' }} size='4x' />
                        </Link>
                    </div>
                    <div className='article-nav-section article-section'>
                        <button className='article-nav-button' onClick={handleLeftClick}>
                            <FontAwesomeIcon icon={faArrowLeft} style={{ color: 'rgb(255, 255, 255, 1)' }} size='2x' />
                        </button>
                        <button className='article-nav-button' onClick={handleRightClick}>
                            <FontAwesomeIcon icon={faArrowRight} style={{ color: 'rgb(255, 255, 255, 1)' }} size='2x' />
                        </button>
                    </div>
                </article>
                <article data-index='5' data-status='inactive'>
                    <div className='article-image-section article-section'></div>
                    <div className='article-description-section article-section'>
                        <p>This gallery lookbook was made for Bo Bridges' collaboration with Marvel: The Hidden Heroes Collection. The Hidden Heroes Collection marks the first time an artist collaborated with Marvel to create photo-real artwork that took Marvel heroes off the pages of comic books and into our real world.</p>
                    </div>
                    <div className='article-title-section article-section'>
                        <h2>Marvel x Bo Bridges Look Book</h2>
                        <Link to='/marvel-lookbook'>
                            <FontAwesomeIcon className='plus' icon={faPlus} style={{ color: 'rgb(255, 255, 255, 0.1)' }} size='4x' />
                        </Link>
                    </div>
                    <div className='article-nav-section article-section'>
                        <button className='article-nav-button' onClick={handleLeftClick}>
                            <FontAwesomeIcon icon={faArrowLeft} style={{ color: 'rgb(255, 255, 255, 1)' }} size='2x' />
                        </button>
                        <button className='article-nav-button' onClick={handleRightClick}>
                            <FontAwesomeIcon icon={faArrowRight} style={{ color: 'rgb(255, 255, 255, 1)' }} size='2x' />
                        </button>
                    </div>
                </article>
            </main>
        </>
    )
}

export default Landing